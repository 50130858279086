export const APPLE_SCRIPT_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js' as const;
export const REDIRECT_URI = 'auth/sign-in/success' as const;
export const SUCCESS_REDIRECT_KEY = 'st_oauth_success_redirect_url' as const;
export const WAS_SUCCESS_REDIRECTED_KEY = 'st_oauth_success_redirected_provider' as const;
export const WAS_SUCCESS_CODE_KEY = 'st_oauth_success_code' as const;

export function calcOffset(width: number, height: number) {
  const windowWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width,
    windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;
  return {
    left: windowWidth / 2 - width / 2 + window.screenLeft,
    top: windowHeight / 2 - height / 2 + window.screenTop,
  };
}

export function checkWasAuthSuccessRedirect(): boolean {
  return (
    !!window.localStorage.getItem(WAS_SUCCESS_REDIRECTED_KEY) &&
    !!window.localStorage.getItem(WAS_SUCCESS_CODE_KEY)
  );
}
